<script>
    import Modal from "$lib/Modal.svelte";
    import TealButton from "$lib/TealButton.svelte";

    let modal;

    export function show() {
        modal.show();
    }
</script>

<Modal bind:this={modal} {...$$restProps}>
    <h1>Success!</h1>
    <p><b>Your account has been activated.</b></p>
    <p>You can now sign in.</p>
    <TealButton style="height:49px" on:click={modal.hide}>OK</TealButton>
</Modal>

<style lang="scss">
  h1 {
    align-self: flex-start;
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50%;
    text-transform: uppercase;
    color: var(--color-modal-title);
  }

  p {
    display: inline-block;
    font-family: Poppins;
    padding: 0;
    margin: 0;
    margin-right: 52px;
    font-size: 14px;
    line-height: 19.6px;
    text-align: left;
  }
</style>
