<script>
    import Background from "$lib/Background.svelte";
    import TextInput from "$lib/TextInput.svelte";
    import EmailIcon from "$lib/EmailIcon.svelte";
    import LockIcon from "$lib/LockIcon.svelte";
    import TransparentButton from "$lib/TransparentButton.svelte";
    import TealButton from "$lib/TealButton.svelte";
    import FlexSpacer from "$lib/FlexSpacer.svelte";
    import params from "$lib/params.js";
    import InvalidSignInMessage from "$lib/InvalidInputMessage.svelte";
    import ModalContainer from "$lib/ModalContainer.svelte";
    import ActivateAccountModal from "$lib/ActivateAccountModal.svelte";
    import KiosLogoHorizontal from "$lib/KiosLogoHorizontal.svelte";
    import VSpacer from "$lib/VSpacer.svelte";
    import ForgotPasswordModal from "$lib/ForgotPasswordModal.svelte";
    import FaceIdIcon from "$lib/FaceIdIcon.svelte";
    import TouchIdIcon from "$lib/TouchIdIcon.svelte";
    import LaunchFooter from "$lib/LaunchFooter.svelte";
    import AccountActivated from "$lib/AccountActivated.svelte";
    import {onMount} from "svelte";
    import SavePasswordModal from "$lib/SavePasswordModal.svelte";

    export let version = "...";

    const tz = new Date().getTimezoneOffset();

    let activateAccountModal;
    let forgotPasswordModal;
    let accountActivatedModal;
    let savePasswordModal;
    let isBiometric = false;

    let creds = {
        username: null,
        password: null
    };

    let form;
    const onClickForgotPassword = e => {
        forgotPasswordModal.show(usernameValue);
    }

    const onClickActivate = e => {
        activateAccountModal.show();
    }

    const onClickSignIn = e => {
        console.log("Sign In");
        console.log("usernameValue: " + usernameValue);
        console.log("passwordValue: " + passwordValue);
        console.log("isNative: " + isNative);
        console.log("canSavePassword: " + canSavePassword);
        console.log("usernameFilled: " + usernameFilled);
        console.log("passwordFilled: " + passwordFilled);
        console.log("creds", creds);
        if (isNative && canSavePassword) {
            console.log('isNative && canSavePassword');
            if (usernameFilled && passwordFilled &&
                (usernameValue != creds.username || passwordValue != creds.password)
            ) {
                console.log('usernameFilled && passwordFilled && usernameValue != creds.username || passwordValue != creds.password');
                // User updated creds, ask to save
                savePasswordModal.show();
                e.preventDefault();
                return;
            }
        }
        form.submit();
    }

    const onSavePassword = (yes, never) => {
        if (yes) {
            send(['saveCredentials', usernameValue, passwordValue]);
            // form submits in handleMessage: savedCredentials
            const isIos = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
            if (isIos || !isBiometric) {
                form.submit();
            }
        } else if (never) {
            send(['neverSaveCredentials']);
            form.submit();
        } else {
            send(['clearCredentials'])
            form.submit();
        }
    }

    const fillBiometric = e => {
        console.log('BiometricIdSignIn');
        send(['askCredentials']);
    }

    let usernameFilled = false;
    let passwordFilled = false;
    let usernameValue = "";
    let passwordValue = "";

    let showBiometricIdSignIn = false;
    let isNative = false;
    let canSavePassword = false;

    const isInvalidSignIn = params.get('error') === 'invalid_signin';


    $:canSignIn = usernameFilled && passwordFilled;


    async function SignIn(username, password) {
        usernameValue = username;
        passwordValue = password;
        usernameFilled = true;
        passwordFilled = true;
        if (isBiometric) {
            await new Promise(r => setTimeout(r, 500));
            form.submit();
        }
    }

    const handleMessage = event => {
        console.log("Server Received: " + event.data);
        const message = JSON.parse(event.data);
        const command = message[0];

        if (command === 'isNative') {
            isNative = true;
            const hasSecret = message[1];
            canSavePassword = message[2];
            isBiometric = message[3];
            console.log('isNative', message);
            if (hasSecret) {
                if (isBiometric) {
                    showBiometricIdSignIn = true;
                } else {
                    showBiometricIdSignIn = false;
                    fillBiometric();
                }
            }

        } else if (command === 'fillCredentials') {
            canSavePassword = message[1];
            const username = message[2];
            const password = message[3];
            if (canSavePassword && username != null && password != null) {
                creds.username = username;
                creds.password = password;
                SignIn(username, password);
            }

        } else if (command === 'savedCredentials') {
             form.submit();
        }
    }

    const send = message => {
        console.log("Server Sent: " + JSON.stringify(message));
        var is_ie = /MSIE|Trident/.test(window.navigator.userAgent);
        if (!is_ie) {
            // parent.postMessage(JSON.stringify(message), 'https://localhost');
            parent.postMessage(JSON.stringify(message), '*');
        }
    }

    send(['checkIfNative']);

    onMount(() => {
        if (params.get('activated') === 'true') {
            accountActivatedModal.show();
        }
    });

</script>

<svelte:window on:message={handleMessage}/>
<Background/>
<ModalContainer>
    <div slot="modals">
        <ActivateAccountModal bind:this={activateAccountModal}/>
        <ForgotPasswordModal bind:this={forgotPasswordModal}/>
        <AccountActivated bind:this={accountActivatedModal}/>
        <SavePasswordModal bind:this={savePasswordModal} onSelect={onSavePassword}/>
    </div>
    <form id="SignInForm" action="/signin" method="POST" bind:this={form}>
        <div class="container">
            <VSpacer size="22px"/>
            <KiosLogoHorizontal/>
            <VSpacer size="1px"/>

            <TextInput
                    type="email"
                    name="user"
                    bind:isFilled={usernameFilled}
                    bind:value={usernameValue}
                    placeholder="Enter your email"
                    autocomplete="username"
                    invalid={isInvalidSignIn}
            >
                <EmailIcon slot="icon"/>
            </TextInput>
            <TextInput
                    isPassword={true}
                    name="pass"
                    bind:isFilled={passwordFilled}
                    bind:value={passwordValue}
                    placeholder="Enter your password"
                    autocomplete="current-password"
                    invalid={isInvalidSignIn}
            >
                <LockIcon slot="icon"/>
            </TextInput>
            {#if isInvalidSignIn}
                <div class="invalid-sign-in">
                    <InvalidSignInMessage/>
                </div>
            {/if}

            <div class="actions">
                <TransparentButton style="height:34px;justify-content: left;" on:click={onClickActivate}>
                    <span class="action-text">First time user?</span>
                </TransparentButton>
                <TransparentButton style="height:34px;justify-content: right;" on:click={onClickForgotPassword}>
                    <span class="action-text">Forgot your password?</span>
                </TransparentButton>
            </div>
            <TealButton style="height:49px" on:click={onClickSignIn} disabled={!canSignIn}>Sign In</TealButton>
            <VSpacer size="16px"/>
            {#if showBiometricIdSignIn}
                <TransparentButton type="button" style="height:49px" on:click={fillBiometric}>
                    <div class="biometric">
                        <FaceIdIcon/>
                        <span class="action-text">Biometric ID Sign In</span>
                        <TouchIdIcon/>
                    </div>
                </TransparentButton>
            {/if}
            <FlexSpacer/>
            <LaunchFooter/>
        </div>
        <input type="hidden" name="tz" value="{tz}"/>
    </form>
</ModalContainer>

<style lang="scss">
  .action-text {
    display: block;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-decoration-line: underline;
  }

  .container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding: 16px;
    gap: 16px;

    .sign-in {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 33px;
      margin-bottom: 4px;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .invalid-sign-in {
      padding-left: 23px;
    }

    .welcome {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
      text-transform: capitalize;
      margin-bottom: 12px;
    }

    .biometric {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
</style>