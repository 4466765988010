<script>
    import Modal from "$lib/Modal.svelte";
    import TextInput from "$lib/TextInput.svelte";
    import InvitationCodeIcon from "$lib/InvitationCodeIcon.svelte";
    import TealButton from "$lib/TealButton.svelte";
    import EmailIcon from "$lib/EmailIcon.svelte";
    import url from "$lib/url";

    let form;
    let modal;
    let input;
    let emailAddress = "";

    function onClickSend() {
        form.submit();
        modal.hide();
    }

    export function show(email) {
        emailAddress = email;
        modal.show();
    }

    function onEnter() {
        onClickSend();
    }

</script>

<form bind:this={form} action={url("/email_recovery_link")} method="get">
    <Modal bind:this={modal}>
        <h1>Reset</h1>
        <p>Please enter the email address you used to register. You'll receive instructions to reset your password.</p>
        <TextInput name="email" type="email" placeholder="Enter your email" border="true" focus={true}
                   bind:value={emailAddress} {onEnter}>
            <div slot="icon">
                <EmailIcon/>
            </div>
        </TextInput>
        <TealButton style="height:49px;" on:click={onClickSend}>Send</TealButton>
    </Modal>
</form>

<style lang="scss">
  h1 {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--color-modal-title);
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
  }
</style>