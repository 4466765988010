<script>
    import Modal from "$lib/Modal.svelte";
    import TealButton from "$lib/TealButton.svelte";
    import SecondaryButton from "$lib/SecondaryButton.svelte";

    let modal;

    export let onSelect = (yes, never) => {
        if (yes) {
            console.log("Yes");
        } else if (never) {
            console.log("Never");
        } else {
            console.log("Not Now");
        }
    }

    export function show() {
        modal.show();
    }

    function onClick(yes, never) {
        modal.hide();
        onSelect(yes, never);
    }

</script>

<Modal bind:this={modal}>
    <h1>Save Password</h1>
    <p>Would you like to save your KIOS password?</p>
    <TealButton style="height: 49px" on:click={e=>{onClick(true,false);}}>Save</TealButton>
    <SecondaryButton style="height: 49px" on:click={e=>{onClick(false,false);}}>Not Now</SecondaryButton>
    <SecondaryButton style="height: 49px" on:click={e=>{onClick(false,true);}}>Never</SecondaryButton>
</Modal>

<style lang="scss">
  h1 {
    align-self: flex-start;
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50%;
    text-transform: uppercase;
    color: var(--color-modal-title);
  }

  p {
    display: inline-block;
    font-family: Poppins;
    padding: 0;
    margin: 0;
    margin-right: 52px;
    font-size: 14px;
    line-height: 19.6px;
    text-align: left;
  }
</style>
