<script>
    import Modal from "$lib/Modal.svelte";
    import TextInput from "$lib/TextInput.svelte";
    import InvitationCodeIcon from "$lib/InvitationCodeIcon.svelte";
    import TealButton from "$lib/TealButton.svelte";

    let modal;
    let input;
    let invitationCode = "";
    let isInvitationCodeFilled = false;
    let form;

    function onClickSend() {
        form.submit();
    }

    export function show() {
        modal.show();
    }

    function onEnter() {
        onClickSend();
    }

    $:sendDisabled = !isInvitationCodeFilled;

</script>

<form bind:this={form} method="post" action="/signup_credentials">
    <Modal bind:this={modal}>
        <h1>Activate your account</h1>
        <p>
            Check your email to find your invitation code. This code only needs to be successfully submitted once.
        </p>
        <TextInput name="id" placeholder="Invitation code" border="true" focus={true} value={invitationCode}
                   bind:isFilled={isInvitationCodeFilled} {onEnter} autocomplete="one-time-code">
            <div slot="icon">
                <InvitationCodeIcon/>
            </div>
        </TextInput>
        <TealButton type="submit" style="height:49px;" on:click={onClickSend} disabled={sendDisabled}>Send</TealButton>
    </Modal>
</form>

<style lang="scss">
  h1 {
    align-self: flex-start;
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--color-modal-title);
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
  }
</style>